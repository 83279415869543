import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import { Suspense, useRef, useEffect } from 'react';
import { TextureLoader } from 'three';
import { useFrame, useLoader } from '@react-three/fiber';
import { Line } from '@react-three/drei';

// Earth Component with Moving Clouds
function Earth() {
    const earthRef = useRef();
    const cloudRef = useRef();

    useFrame(() => {
        if (earthRef.current) earthRef.current.rotation.y += 0.001;
        if (cloudRef.current) cloudRef.current.rotation.y += 0.0015;
    });

    return (
        <group>
            <mesh ref={earthRef} position={[0, 0, 0]}>
                <sphereGeometry args={[30, 64, 64]} />
                <meshStandardMaterial
                    map={useLoader(TextureLoader, 'https://threejs.org/examples/textures/planets/earth_atmos_2048.jpg')}
                />
            </mesh>
            <mesh ref={cloudRef} position={[0, 0, 0]}>
                <sphereGeometry args={[30.5, 64, 64]} />
                <meshStandardMaterial
                    map={useLoader(TextureLoader, 'https://cdn.jsdelivr.net/gh/mrdoob/three.js@r150/examples/textures/planets/earth_clouds_2048.png')}
                    transparent
                    opacity={0.4}
                />
            </mesh>
        </group>
    );
}

// Communication Network Grid Component
function CommNetwork() {
    const linesRef = useRef([]);
    const pointsRef = useRef([]);

    // Generate network nodes
    const nodes = React.useMemo(() => {
        const count = 150;
        const nodes = [];
        for (let i = 0; i < count; i++) {
            const phi = Math.acos(-1 + (2 * i) / count);
            const theta = Math.sqrt(count * Math.PI) * phi;
            nodes.push({
                position: [
                    32 * Math.cos(theta) * Math.sin(phi),
                    32 * Math.sin(theta) * Math.sin(phi),
                    32 * Math.cos(phi)
                ],
                connections: []
            });
        }
        return nodes;
    }, []);

    // Create connections between nearby nodes
    const connections = React.useMemo(() => {
        const maxDistance = 15;
        const result = [];
        nodes.forEach((a, i) => {
            nodes.forEach((b, j) => {
                if (i !== j && Math.random() < 0.1) {
                    const distance = Math.hypot(...a.position.map((v, k) => v - b.position[k]));
                    if (distance < maxDistance) {
                        result.push([a.position, b.position]);
                    }
                }
            });
        });
        return result;
    }, [nodes]);

    // Animation loop
    useFrame(({ clock }) => {
        linesRef.current.forEach((line, i) => {
            if (line && line.material) {
                line.material.opacity = 0.3 + Math.sin(clock.elapsedTime * 2 + i) * 0.2;
            }
        });

        pointsRef.current.forEach((point, i) => {
            if (point) {
                point.position.y = nodes[i].position[1] + Math.sin(clock.elapsedTime + i) * 0.5;
                point.position.x = nodes[i].position[0] + Math.sin(clock.elapsedTime + i) * 0.5;
                point.position.z = nodes[i].position[2] + Math.sin(clock.elapsedTime + i) * 0.5;
            }
        });
    });

    return (
        <group position={[0, 0, 0]}>
            {/* Connection Lines */}
            {connections.map(([start, end], i) => (
                <Line
                    key={i}
                    ref={(el) => linesRef.current[i] = el}
                    points={[start, end]}
                    color="#00ffff"
                    transparent
                    opacity={0.3}
                    lineWidth={0.5}
                />
            ))}

            {/* Network Nodes */}
            {nodes.map((node, i) => (
                <mesh
                    key={`node-${i}`}
                    ref={(el) => (pointsRef.current[i] = el)}
                    position={node.position}
                >
                    <sphereGeometry args={[0.15]} />
                    <meshStandardMaterial
                        color="#00ffff"
                        emissive="#00ffff"
                        emissiveIntensity={1.5}
                    />
                </mesh>
            ))}

            {/* Data Pulses */}
            <mesh position={[0, 0, 0]}>
                <sphereGeometry args={[30.8, 64, 64]} />
                <meshStandardMaterial
                    color="#00ffff"
                    emissive="#00ffff"
                    transparent
                    opacity={0.1}
                    wireframe
                    emissiveIntensity={2}
                />
            </mesh>
        </group>
    );
}

// ArtemisX Logo
function LogoImage() {
    return (
        <img
            src="/logo.png"
            alt="ArtemisX Logo"
            style={{
                position: 'absolute',
                top: '5%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '300px',
                pointerEvents: 'none',
                zIndex: 1,
                opacity: 0.9
            }}
        />
    );
}

// Styled Text Component (No Glitch)
function StyledText() {
    useEffect(() => {
        // Load the font dynamically
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Audiowide&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    }, []);

    return (
        <div style={{
            position: 'absolute',
            bottom: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            pointerEvents: 'none',
            zIndex: 2,
            fontSize: '3rem',
            fontWeight: 'normal', // Audiowide looks better with normal font weight
            fontFamily: '"Audiowide", sans-serif',
            textTransform: 'uppercase',
            color: 'white',
            textAlign: 'center',
            letterSpacing: '0.1em',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
        }}>
            Launching Soon
        </div>
    );
}

// Updated Scene Component
function Scene() {
    return (
        <>
            <ambientLight intensity={2} />
            <directionalLight intensity={3} position={[-50, -50, -50]} color={0xffcc88} />
            <Stars radius={300} depth={60} count={5000} factor={7} saturation={0} fade />
            <Suspense fallback={null}>
                <Earth />
                <CommNetwork />
            </Suspense>
        </>
    );
}

// Main Component
export default function LandingPage() {
    return (
        <div style={{ width: '100vw', height: '100vh', background: '#000', position: 'relative' }}>
            <LogoImage />
            <StyledText />

            <Canvas camera={{ position: [0, -5, 80] }}>
                <Scene />
                <OrbitControls enableZoom={false} />
            </Canvas>
        </div>
    );
}
